import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout
    sermonSrc="https://www.youtube.com/embed/5gK4tLRtyw8"
    bibleGroupSrc="https://pdfhost.io/v/L7XZUx.w~_Bible_Group_Homework_1025pdf.pdf"
    bibleGroupText="Bible Group Homework"
    notesText="Sermon Notes"
    notesSrc="http://bible.com/events/46012330"
  >
    <SEO title="The Blessed Life - Holiday on its Head" />
  </Layout>
)

export default SermonPost
